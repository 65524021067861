section#Works {
    padding: 110px 70px 140px;
    background-color: #f3f3f3;
    text-align: center;
}

@media only screen and ( max-width: 1023px ) {

    section#Works {
        padding: 50px 50px 60px;
    }

}