.intro-shortcut-item {
  position: absolute;
  z-index: 6;
  padding: 40px;
  width: 50%;
  height: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.intro-shortcut-item::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 1;
  transition: 300ms opacity;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  content: '';
}

.intro-shortcut-item:hover::before {
  opacity: 0;
}

.intro-shortcut-item h4,
.intro-shortcut-item h5 {
  margin: 0;
}

.intro-shortcut-item h4 {
  font-size: 36px;
  font-weight: 200;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.intro-shortcut-item h5 {
  font-size: 12px;
  font-weight: 500;
}

.intro-noper {
  top: 0;
  left: 0;
  background-position-x: left;
  background-position-y: 45%;
  background-size: 140%;
  background-color: #73a0e1;
}

.intro-noper h4 {
  font-size: 32px;
  font-weight: 200;
}

.intro-noper h5,
.intro-about h4 {
  margin-top: 20px;
}

.intro-about {
  top: 0;
  left: 50%;
  background-position: center;
}

.intro-works {
  top: 50%;
  left: 0;
  background-position: center left;
  background-size: 120%;
  background-color: #c59c70;
}

.intro-projects {
  top: 50%;
  left: 50%;
}

@media only screen and (max-width: 1360px) {
  .intro-shortcut-item {
    padding: 30px;
  }

  .intro-shortcut-item::before {
    opacity: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .intro-shortcut-item {
    display: inline-block;
    position: relative;
    left: auto;
    top: auto;
    width: 150px;
    height: 100px;
    vertical-align: top;
  }

  .intro-shortcut-item + .intro-shortcut-item {
    margin-left: 30px;
  }

  .intro-shortcut-item h4 {
    position: absolute;
    bottom: -25px;
    left: 0;
    font-size: 20px;
  }

  .intro-shortcut-item h5 {
    position: absolute;
    bottom: -15px;
    left: 0;
    font-size: 10px;
    font-weight: 200;
    color: #ffffff;
  }

  .intro-noper h4 {
    bottom: -30px;
  }
}
